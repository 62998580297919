<template>
  <div>
    <Horizontal
      ref="platfomCoin"
      v-if="$store.getters.currentPageVersion === 'Horizontal'"
      :orderInfo="orderInfo"
    ></Horizontal>
    <Vertical ref="platfomCoin" v-else :orderInfo="orderInfo"></Vertical>
  </div>
</template>

<script>
import Horizontal from "@/components/Mine/PlatformCoin/Horizontal/OrderDetail.vue";
import Vertical from "@/components/Mine/PlatformCoin/Vertical/OrderDetail.vue";

export default {
  components: {
    Horizontal,
    Vertical,
  },
  data() {
    return {
      orderInfo: {},
    };
  },
  created() {
    this.getOrderDetail();
  },
  methods: {
    getOrderDetail() {
      let requestData = {
        user_id: this.$store.state.userId,
        token: this.$store.state.token,
        order_id: this.$route.params.item.order_id,
      };
      this.$api.pay.getPlatFormCoinRechargeDetail(requestData).then((res) => {
        if (res.data.code === 200) {
          this.orderInfo = res.data.data;
          this.orderInfo.real_money = 0;
          if (this.orderInfo.pay_compose && this.orderInfo.pay_way_code) {
            for (let i in this.orderInfo.pay_compose) {
              if (i === this.orderInfo.pay_way_code) {
                this.orderInfo.real_money = this.orderInfo.pay_compose[i];
              }
            }
          } else {
            this.orderInfo.real_money = this.orderInfo.final_pay_money;
          }
        }
      });
    },
  },
};
</script>

<style>
</style>